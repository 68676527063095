export const copyToClipboard = (str: string) => {
  const node = document.createElement('textarea');

  node.setAttribute('style', 'position:absolute;left:-999px');
  document.body.appendChild(node);
  node.value = str;
  node.select();
  document.execCommand('copy');
  document.body.removeChild(node);
};
