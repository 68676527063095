import styled from 'styled-components';
import { boxShadow, color, font } from '../../config';

const ActionLink = styled.button`
  background-color: transparent;
  border-radius: 0.5em;
  border: none;
  color: ${color.primary.base};
  cursor: pointer;
  font-size: 1em;
  font-weight: ${font.weight.bold};
  line-height: 1.5em;
  margin-left: -0.5em;
  padding: 0.5em;
  text-decoration: underline;
  text-underline-offset: 0.375em;

  &:focus {
    box-shadow: ${boxShadow.primaryThin};
    outline: none !important;
  }
`;

export default ActionLink;
