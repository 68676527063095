import styled from 'styled-components';
import { color } from '../../../config';

type InputButtonProps = {
  disabled?: boolean;
  copied?: boolean;
};

export const InputButton = styled.div<InputButtonProps>`
  display: flex;
  align-items: center;
  width: 52rem;
  input {
    border-radius: 0.5em 0 0 0.5em;
    height: 4.4rem;
    border-right: none;
    border-color: ${(props) =>
      props.copied
        ? color.success.foreground
        : props.disabled
        ? color.grey.light
        : color.primary.base};
  }
  button {
    border-radius: 0 0.5em 0.5em 0;
    white-space: nowrap;
    min-width: auto;
    margin-top: 0.5em;
  }
`;
