import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { IconSwitch } from '../../icons';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { CreateCourseHeader } from '../ModalContent.style';
import { fetchAPI } from '../../../utils/fetch-api';
import { IEnquiry } from '../../../context-providers/enquiries/enquiries-reducer';

interface ITransferEnquiry {
  enquiry: IEnquiry;
  onSuccess?: () => void;
}

interface ITransferLearner {
  enquiryId: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface EnquiryName {
  firstName: string;
  secondName: string;
}

const splitStringByLastSpace = (inputString: string): EnquiryName => {
  const lastSpaceIndex = inputString.lastIndexOf(' ');

  if (lastSpaceIndex === -1 || lastSpaceIndex === inputString.length - 1) {
    return { firstName: inputString, secondName: '' };
  }

  const firstName = inputString.slice(0, lastSpaceIndex);
  const secondName = inputString.slice(lastSpaceIndex + 1);

  return { firstName, secondName };
};

const TransferLearner = ({ enquiry, onSuccess }: ITransferEnquiry) => {
  const closeModal = useCloseModal();

  const handleSubmit = async () => {
    ReactGA.event({
      category: 'Button',
      action: 'Transfer Enquiry to Learner modal - Transfer',
    });

    const { firstName, secondName } = splitStringByLastSpace(
      enquiry.enquiryName,
    );

    const transferRequest: ITransferLearner = {
      enquiryId: parseInt(enquiry.id, 10),
      firstName: firstName,
      lastName: secondName,
      email: enquiry.emailAddress,
    };

    const res = await fetchAPI({
      path: 'enquiries/transfer',
      body: transferRequest,
      method: 'POST',
    });
    if (res.success) {
      onSuccess && onSuccess();
      closeModal();
    }
  };

  return (
    <Stack stackMultiplier={2}>
      <CreateCourseHeader>
        <IconSwitch width={25} height={25} />
        <h2>Transfer Enquiry to Learner</h2>
      </CreateCourseHeader>

      <p style={{ maxWidth: '39rem' }}>
        Accepting this enquiry will transfer the record to a learner record.
        Learners can then be sent regular forms.
      </p>
      <p>Transfer {enquiry.enquiryName} to a learner?</p>

      <ButtonRow>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
          dataQa="deleteData-btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          iconLeft={<IconSwitch />}
          type="submit"
          dataQa="editCourse-btn-update"
        >
          Transfer enquiry
        </Button>
      </ButtonRow>
    </Stack>
  );
};

export default TransferLearner;
