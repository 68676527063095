import styled from 'styled-components';
import { boxShadow, color, font } from '../config';
import { HTMLAttributes } from 'react';

type InputButtonProps = {
  disabled?: boolean;
  copied?: boolean;
};

type IAddSectorButton = {
  disabled?: boolean;
};

type IAddCircle = {
  error?: boolean;
};

export const Title = styled.h2`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2rem;
  max-width: 50rem;
`;

export const SubTitle = styled.h3`
  font-size: 2rem;
  font-weight: ${font.weight.bold};
  max-width: 50rem;
`;

export const SmallTitle = styled.h4`
  font-size: 1.4rem;
  font-weight: ${font.weight.normal};
  max-width: 50rem;
`;

export const ActionButtons = styled.div`
  button {
    margin-right: 2.4rem;
  }

  button:last-child {
    margin-right: 0;
  }
`;

export const ActionButtonsSlim = styled(ActionButtons)`
  margin-top: 3.2rem;
`;

export const AnimatedText = styled.div`
  transition: all 0.3s ease-in;
`;

export const InputButton = styled.div<InputButtonProps>`
  display: flex;
  align-items: flex-end;
  input {
    border-radius: 0.5em 0 0 0.5em;
    height: 2.85rem;
    border-right: none;
    border-color: ${(props) =>
      props.copied
        ? color.success.foreground
        : props.disabled
        ? color.grey.light
        : color.primary.base};
  }
  button {
    height: 2.85rem;
    border-radius: 0 0.5em 0.5em 0;
    white-space: nowrap;
  }
`;

export const AddSectorButton = styled.button<IAddSectorButton>`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  padding: 1.2rem;
  border-radius: 0.8rem;
  border: 1px dashed
    ${(props) => (props.disabled ? color.grey.light : color.primary.base)};
  font-size: 1.8rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: ${color.dark};
  background: ${(props) => (props.disabled ? color.grey.lightest : 'none')};

  &:focus {
    outline-offset: 2px;
    outline: 2px solid ${color.primary.base};
  }
`;

export const AddCircle = styled.div<IAddCircle>`
  width: 4rem;
  height: 4rem;
  border-radius: 999px;
  background-color: ${(props) =>
    props.error ? color.error.foreground : color.grey.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  color: ${(props) => (props.error ? color.light : color.primary.base)};
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 1.4rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  margin-top: 0.3rem;

  &.column {
    flex-direction: column;
    align-items: start;
  }

  & > * {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
`;

export const SectorField = styled.div`
  position: relative;

  & > button {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    border: 0;
    color: ${color.grey.base};
    background: none;
    cursor: pointer;
    width: 4.4rem;
    height: 4.8rem;
    transition: color 0.2s ease-in;

    line-height: 1;
    svg {
      vertical-align: middle;
    }

    &:hover {
      color: ${color.grey.dark};
    }
  }
`;

export const DetailsCard = styled.div`
  background-color: ${color.secondary.base};
  padding: 2rem;
  border-radius: 0.8rem;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ModalText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${color.grey.dark};
`;

type TCloseIconButton = HTMLAttributes<HTMLButtonElement>;

export const CloseIconButton = styled.button<TCloseIconButton>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  z-index: 999;
  display: flex;
  margin-right: 26px;
  border-radius: 6px;

  &:focus {
    box-shadow: ${boxShadow.primary};
  }
`;

export const CombinedHeaderRightIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2.21rem;
  min-width: 40rem;

  button {
    margin: 0;
    border: 1px solid transparent;

    &:active,
    &:hover {
      border-color: black;
    }
  }

  h2 {
    margin-left: 0.75rem;
    text-align: center;
  }
`;

export const CombinedHeaderLeftRightIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2.21rem;
  min-width: 40rem;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
  }

  button {
    margin: 0;
    border: 1px solid transparent;

    &:active,
    &:hover {
      border-color: black;
    }
  }

  h2 {
    margin-left: 0.75rem;
    text-align: center;
    line-height: 1;
  }
`;

export const CreateCourseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2.21rem;

  h2 {
    margin-left: 0.75rem;
    text-align: center;
  }
`;

export const CreateUserHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2.21rem;

  h2 {
    margin-left: 0.75rem;
    text-align: center;
  }
`;

export const LearnerEnrolHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2.21rem;

  h2 {
    margin-left: 0.75rem;
    text-align: center;
  }
`;

export const CreateCourseSubtitle = styled(SubTitle)`
  margin-top: 1.5rem;
`;

export const CreateUserSubtitle = styled(SubTitle)`
  padding-top: 0;
`;

export const CreateUserSmallTitle = styled(SmallTitle)`
  padding: 0;
  margin-left: 3rem;
`;

export const EditEmployerContactLeftRightIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2.21rem;
  min-width: 40rem;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
  }

  button {
    margin: 0;
    border: 1px solid transparent;
    justify-self: flex-end;

    &:active,
    &:hover {
      border-color: black;
    }
  }

  h2 {
    margin-left: 0.75rem;
    text-align: center;
    justify-self: flex-start;
    line-height: 1;
    font-weight: 700;
    font-size: 2.25rem;
  }
`;
