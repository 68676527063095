import React, { ChangeEvent, useEffect, useState } from 'react';
import { IconChevronCollapse, IconChevronExpand, IconClose } from '../../icons';
import { Box } from '../custom-elements/Box.style';
import { FlexGrid } from '../custom-elements/Flex.style';
import { TabColour } from '../pill/tab-color';
import SearchableInput from '../searchable-input/searchable-input';
import Stack from '../stack/Stack';
import { FilterItem } from './models/filter-item';
import {
  SearchError,
  FilterBox,
  FilterButton,
  FilterCheckboxScroll,
  FilterContainer,
  FilterDropdown,
  FilteredItem,
} from './FilterSingleItem.style';
import { font } from '../../config';
import { FilterSubText } from './FilterSubText.style';

interface FilterProps {
  filterName: string;
  items: FilterItem[];
  title: string;
  disabled?: boolean;
  searchTitle?: string;
  emptySearchMessage?: string;
  initalValue?: FilterItem;
  onItemChanged: (selectedValue: FilterItem) => void;
  toggleMenu: (isMenuOpen: boolean) => void;
}

const FilterSingleItem = ({
  filterName,
  items,
  disabled = false,
  title,
  searchTitle = undefined,
  emptySearchMessage = 'No results found. Please try again.',
  initalValue,
  onItemChanged,
  toggleMenu,
}: FilterProps) => {
  const [values, setValues] = useState<FilterItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<FilterItem | undefined>(
    initalValue,
  );
  const [opened, setOpened] = useState<boolean>(false);
  const [filteredValues, setFilteredValues] = useState<FilterItem[]>(values);
  const [searchCriteria, setSearchCriteria] = useState<string>('');

  const handleItemClick = (item: FilterItem): void => {
    setAndEmitValues(item);
    toggleDropdown();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const searchFilter: string = e?.currentTarget?.value?.toLowerCase() ?? '';
    setSearchCriteria(searchFilter);
    if (!searchFilter) {
      setFilteredValues(values);
    } else {
      setFilteredValues(
        values.filter((v) => v.name.toLowerCase().indexOf(searchFilter) !== -1),
      );
    }
  };

  const clearSearch = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setSearchCriteria('');
    setFilteredValues(values);
  };

  const clearAll = () => {
    values.forEach((item) => {
      item.selected = false;
    });
  };

  const stopEvent = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const hasSomeParentTheClass = (
    element: Partial<HTMLElement | null>,
    classname: string,
  ): boolean => {
    if (element == null) {
      return false;
    }

    if (
      element.id &&
      element.id.indexOf &&
      element.id.indexOf(classname) >= 0
    ) {
      return true;
    }

    if (element.parentNode) {
      return hasSomeParentTheClass(
        element.parentNode as HTMLElement,
        classname,
      );
    }

    return false;
  };

  const setAndEmitValues = (selectedValue: FilterItem) => {
    setSelectedValue(selectedValue);
    onItemChanged(selectedValue);
  };

  const checkOutsideClick = (ev: MouseEvent) => {
    if (!hasSomeParentTheClass(ev.target, '')) {
      toggleMenu(false);
      closeDropdown();
    }
  };

  const closeDropdown = () => {
    window.removeEventListener('click', checkOutsideClick);
    setOpened(false);
  };

  const openDropdown = () => {
    toggleMenu(true);
    setOpened(true);
    window.addEventListener('click', checkOutsideClick);
  };

  const toggleDropdown = () => {
    if (opened) {
      toggleMenu(false);
      closeDropdown();
    } else {
      toggleMenu(true);
      openDropdown();
    }
  };

  useEffect(() => {
    const mapped = items.map((i) => ({
      id: i.id,
      name: i.name,
      selected: !!i.selected,
      color: i.color ?? TabColour.blue,
      subText: i.subText,
    }));

    setValues(mapped);
    setFilteredValues(
      mapped.filter((v) => v.name.toLowerCase().indexOf(searchCriteria) !== -1),
    );
  }, [items, searchCriteria]);

  return (
    <FilterContainer
      id={filterName ? filterName : 'FilterSingleItemUnsetId'}
      useColumn={true}
    >
      <FilterBox onClick={toggleDropdown} disabled={disabled}>
        <FlexGrid>{selectedValue?.name ? selectedValue.name : title}</FlexGrid>
        {values.filter((item) => item.selected).length > 0 && (
          <Box padding="0 0 0 0.5em;">
            <FilterButton
              padding="0.2em"
              onClick={clearAll}
              disabled={disabled}
              aria-label={`${filterName} remove all`}
            >
              <IconClose height={16} width={16} />
            </FilterButton>
          </Box>
        )}
        <FilterButton
          padding="0.2em"
          aria-expanded={opened}
          aria-label={`${filterName} dropdown`}
          onClick={(event) => {
            stopEvent(event);
            toggleDropdown();
          }}
        >
          {opened ? (
            <IconChevronCollapse height={16} width={16} />
          ) : (
            <IconChevronExpand height={16} width={16} />
          )}
        </FilterButton>
      </FilterBox>
      {opened && (
        <>
          <FilterDropdown>
            {searchTitle && (
              <Box padding="1em 1em 0 1em;">
                <SearchableInput
                  onChange={handleChange}
                  value={searchCriteria}
                  placeholder={searchTitle}
                  onClear={clearSearch}
                />
              </Box>
            )}
            <FilterCheckboxScroll>
              <Stack stackMultiplier={0.5}>
                {filteredValues.length > 0 ? (
                  filteredValues.map((item, index) => (
                    <FilteredItem
                      key={`${filterName}-item-${index}`}
                      tabIndex={0}
                      id={`${filterName}-item-${index}`}
                      onKeyDown={(event) =>
                        event.key === 'Enter' || event.key === ' '
                          ? handleItemClick(item)
                          : null
                      }
                      onClick={() => handleItemClick(item)}
                    >
                      <p>{item.name}</p>
                      {item.subText ? (
                        <FilterSubText>
                          <p>{item.subText}</p>
                        </FilterSubText>
                      ) : null}
                    </FilteredItem>
                  ))
                ) : (
                  <SearchError>{emptySearchMessage}</SearchError>
                )}
              </Stack>
            </FilterCheckboxScroll>
          </FilterDropdown>
        </>
      )}
    </FilterContainer>
  );
};

export default FilterSingleItem;
