import React, { useEffect, useState } from 'react';
import ReactGA from '../../../../utils/google-analytics';
import { copyToClipboard } from '../../../../utils/copy-to-clipboard';
import Button from '../../button/Button';
import Stack from '../../stack/Stack';
import Label from '../label/Label';
import TextInput from '../text-input/TextInput';
import { InputButton } from './CopyText.style';

export interface ICopyText {
  disabled?: boolean;
  value: string;
  label?: string;
  description?: string;
  id?: string;
}

const CopyText = ({
  disabled = true,
  value,
  label,
  description,
  id = 'CopyText',
}: ICopyText) => {
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    setIsCopied(false);
  }, [value]);

  return (
    <Stack stackMultiplier={0.5}>
      {label && <Label htmlFor={id}>{label}</Label>}
      {description && <p>{description}</p>}
      <InputButton disabled={disabled} copied={isCopied}>
        <TextInput id={id} value={value} disabled={disabled} />
        <Button
          variant={isCopied ? 'success' : 'primary'}
          disabled={!value}
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: `${window.location.pathname} - Copy link`,
            });
            copyToClipboard(value);
            setIsCopied(true);
          }}
        >
          {isCopied ? 'Copied' : 'Copy Link'}
        </Button>
      </InputButton>
    </Stack>
  );
};

export default CopyText;
