import { FilterItem } from '../components/atoms/filter/models/filter-item';
import { IEnquiry } from '../context-providers/enquiries/enquiries-reducer';
import { IFormTemplate } from '../context-providers/forms/forms-reducer';
import { fetchAPI } from './fetch-api';

interface IFormTemplateUtil {
  getById(id: string): Promise<IEnquiry>;
  setViewed(id: string): Promise<void>;
  getEnquiryFormTemplates(): Promise<IFormTemplate[]>;
  setComplete(id: string): Promise<void>;
}

const EnquiryUtil: IFormTemplateUtil = {
  async getById(id: string): Promise<IEnquiry> {
    const response = await fetchAPI<IEnquiry>({
      path: `enquiries/${id}`,
      method: 'GET',
    });
    if (!response.success) {
      throw new Error('Failed to get enquiry');
    }

    return response.body;
  },
  async setViewed(id: string): Promise<void> {
    const response = await fetchAPI<void>({
      path: `enquiries/${id}`,
      method: 'PATCH',
    });
    if (!response.success) {
      throw new Error('Failed to set viewed');
    }
  },
  async getEnquiryFormTemplates(): Promise<IFormTemplate[]> {
    const response = await fetchAPI<IFormTemplate[]>({
      path: `formtemplates/enquiry-forms`,
      method: 'GET',
    });
    if (!response.success) {
      throw new Error('Failed to get enquiry form templates');
    }

    return response.body;
  },
  async setComplete(id: string): Promise<void> {
    const response = await fetchAPI<void>({
      path: `enquiries/complete/${id}`,
      method: 'PATCH',
    });
    if (!response.success) {
      throw new Error('Failed to set complete');
    }
  },
};

export default EnquiryUtil;
