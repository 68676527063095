import React, { useEffect } from 'react';
import Stack from '../../atoms/stack/Stack';
import CopyText from '../../atoms/form-fields/copy-text/CopyText';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import EnquiryUtil from '../../../utils/enquiry-util';
import Label from '../../atoms/form-fields/label/Label';
import {
  CloseIconButton,
  CombinedHeaderRightIcon,
} from '../ModalContent.style';
import { IconClose } from '../../icons';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';

const NewEnquiry = () => {
  const closeModal = useCloseModal();

  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [enquiryFormOptions, setEnquiryFormOptions] = React.useState<
    FilterItem[]
  >([]);
  const [enquiryFormsLoaded, setEnquiryFormsLoaded] =
    React.useState<boolean>(false);
  const [formLink, setFormLink] = React.useState<string>('');
  const [selectedForm, setSelectedForm] = React.useState<FilterItem>({
    id: '',
    name: '',
  });

  const getEnquiryFormTemplates = (): void => {
    EnquiryUtil.getEnquiryFormTemplates().then((response) => {
      let enquiryFormOptions: FilterItem[] = response.map((i) => ({
        id: i.id ?? '',
        name: i.formName,
      }));
      setEnquiryFormOptions(enquiryFormOptions);
      setEnquiryFormsLoaded(true);
    });
  };

  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  const handleFormChanged = (selectedItem: FilterItem): void => {
    setSelectedForm(selectedItem);
    setFormLink(
      `${window.location.origin}/enquiry-form/?fid=${selectedItem.id}`,
    );
  };

  useEffect(() => {
    getEnquiryFormTemplates();
  }, [enquiryFormsLoaded]);

  return (
    <div style={isMenuOpen ? { height: 400 } : {}}>
      <Stack stackMultiplier={2}>
        <Stack>
          <CombinedHeaderRightIcon>
            <h2>Create Enquiry</h2>
            <CloseIconButton
              aria-label="delete selected csv file"
              data-qa="newenquiry-btn-delete"
              onKeyDown={(e) => e.key === 'Escape' && closeModal()}
              tabIndex={0}
              onClick={() => closeModal()}
            >
              <IconClose width={25} height={25} />
            </CloseIconButton>
          </CombinedHeaderRightIcon>
          <p>Select which enquiry to create a new enquiry.</p>
          <p>
            <i>
              Note: Usage via embedding the results into an HTML IFrame is not
              supported.
            </i>
          </p>
          <Label>Select Enquiry Form</Label>
          <FilterSingleItem
            filterName="enquiry-form-filter"
            items={enquiryFormOptions}
            title="Enquiry Form"
            searchTitle="Search for an enquiry form"
            onItemChanged={handleFormChanged}
            toggleMenu={toggleMenu}
          />
        </Stack>
        <Stack>
          <CopyText
            id="CopyMagicLink"
            value={formLink}
            label="Enquiry Form Link"
            description={`The below link will allow anyone to access ${selectedForm.name} and create an enquiry.`}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default NewEnquiry;
