import styled, { css } from 'styled-components';
import { boxShadow, color } from '../../config';
import { ScrollbarCSS } from '../custom-elements/scrollbar';

export const FilterBox = styled.div<{ disabled?: boolean; useRow?: boolean }>`
  align-items: center;
  cursor: inherit;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0.5em 0.5em 1em;
  user-select: none;
  width: 100%;
  line-height: 1.5em;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${color.grey.lightest};
      border-color: ${color.grey.light};
      pointer-events: none;
    `}
  ${(props) =>
    props.useRow &&
    css`
      flex-direction: column;
      flex: 1;
      position: relative;
    `}
`;

export const FilterContainer = styled.div<{ useColumn?: boolean }>`
  background: ${color.light};
  border-radius: 8px;
  border: 1px solid ${color.grey.light};
  cursor: pointer;
  display: flex;
  min-width: 17.5em;
  position: relative;
  margin-top: 0.75rem;
  ${(props) =>
    props.useColumn &&
    css`
      flex-direction: column;
    `}
`;

const numberOfItemsShownBeforeScroll = 6;
export const FilterCheckboxScroll = styled.div`
  max-height: calc(
    (${numberOfItemsShownBeforeScroll} * 1.5em) +
      (${numberOfItemsShownBeforeScroll - 1} * 1.5em) + 2em
  );
  margin-right: 0.25em;
  overflow-y: auto;
  padding: 1em 0.25em 1em 1em;

  ${ScrollbarCSS}
`;

interface IFilterButtonProps {
  padding?: string | undefined;
}

export const FilterButton = styled.button<IFilterButtonProps>`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  &:focus {
    border-radius: 0.5em;
    box-shadow: ${boxShadow.primaryThin};
    outline: none !important;
  }

  ${(props) => (props.padding ? `padding:${props.padding}` : '')}
`;

export const FilterDropdown = styled.div`
  background: ${color.light};
  border-radius: 0.5em;
  box-shadow: ${boxShadow.grey};
  position: relative;
  top: calc(100% + 0.5em);
  user-select: none;
  width: 100%;
  z-index: 1000;
  white-space: pre-wrap;
`;

export const SearchError = styled.div`
  font-size: 0.875em;
  line-height: 1.5em;
  text-align: center;
`;

export const FilteredItem = styled.div`
  padding: 1rem;

  &:focus {
    border-radius: 0.5em;
    box-shadow: ${boxShadow.primaryThin};
    outline: none !important;
    white-space: pre-wrap;
  }
`;
