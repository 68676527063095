import React from 'react';
import { LabelContainer } from './Label.style';

export interface ILabel extends React.HTMLAttributes<HTMLDivElement> {
  htmlFor?: string;
  children: React.ReactNode;
  dataQa?: string;
}

const Label = ({ htmlFor, children, dataQa, className }: ILabel) => {
  if (!children) {
    return null;
  }
  return (
    <LabelContainer htmlFor={htmlFor} data-qa={dataQa} className={className}>
      {children}
    </LabelContainer>
  );
};

export default Label;
