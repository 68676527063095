import styled from 'styled-components';
import { font } from '../../../config';

export const LabelContainer = styled.label`
  display: block;
  font-size: 2rem;
  position: relative;
  font-weight: ${font.weight.bold};
  word-wrap: break-word;
`;
