import React, { ChangeEvent, FocusEvent, useState } from 'react';
import {
  LabelText,
  LabelWrapper,
  MandatoryIndicator,
  ShowWhiteSpace,
} from '../../../layout/Layout.style';
import Message from '../../message/Message';
import Stack from '../../stack/Stack';
import Label from '../label/Label';
import Button from '../../button/Button';
import {
  FormPrepopFieldReview,
  Input,
  PrePopSubText,
  PrePopUndoWrapper,
  TextAreaCharacterCount,
  TInputAttributes,
  FormFieldReview,
  PrePopSubTextReview,
  TextInputContainer,
} from './TextInput.style';
import { IconLightning, IconError } from '../../../icons';
const renderHTML = require('react-render-html');

export type TTextInput = TInputAttributes & {
  label?: string;
  description?: string;
  error?: string;
  onChangeText?: (arg1: string) => void;
  as?: 'input' | 'textarea';
  id: string; // forces id input attribute to be required
  dataQa?: string;
  showCharacterCount?: boolean;
  showErrorImmediately?: boolean;
  isPrePop?: boolean;
  isFormApproval?: boolean;
  oldValue?: string;
  userTypeName?: string;
  prepopReviewing?: boolean;
  suffix?: string;
  inline?: boolean;
  isEnquiry?: boolean;
};

const TextInput = ({
  label,
  description,
  error,
  dataQa,
  onChangeText,
  as = 'input',
  onBlur,
  onChange,
  showCharacterCount,
  showErrorImmediately = false,
  isPrePop = false,
  isFormApproval = false,
  oldValue = '',
  userTypeName = '',
  prepopReviewing = false,
  prefix,
  suffix,
  inline = false,
  isEnquiry,
  ...inputAttributes
}: TTextInput) => {
  const [showError, setShowError] = useState(showErrorImmediately);
  const [prePopInitialValue] = useState(inputAttributes.value);
  const [prePopChange, setPrePopChange] = useState<boolean>(false);
  const [prePopValue, setPrePopValue] = useState(inputAttributes.value);
  const [prePopOldValue] = useState(oldValue);
  const errorText = showError ? error : '';
  const errorId = `${inputAttributes.id}-error`;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>,
  ) => {
    onChangeText && onChangeText(e.currentTarget.value);
    onChange && onChange(e);

    if (isPrePop) {
      setPrePopChange(true);
      setPrePopValue(e.target.value);
    }
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>,
  ) => {
    setShowError(true);
    onBlur && onBlur(e);
  };

  const handleUndo = () => {
    if (isPrePop && prePopInitialValue) {
      onChangeText && onChangeText(prePopInitialValue.toString());
      setPrePopValue(prePopInitialValue);
      setPrePopChange(false);
    }
  };

  return (
    <Stack stackMultiplier={0.5}>
      <Label
        htmlFor={inputAttributes.id}
        dataQa={`form-lbl-${label?.split(' ')[0]}`}
      >
        <LabelWrapper>
          <LabelText>{renderHTML(label ?? '')}</LabelText>
          <MandatoryIndicator>
            {inputAttributes.required && '*'}
          </MandatoryIndicator>
        </LabelWrapper>
      </Label>

      {description && (
        <ShowWhiteSpace>
          <label
            htmlFor={inputAttributes.id}
            data-qa={`form-desc-${description?.split(' ')[0]}`}
          >
            {renderHTML(description ?? '')}
          </label>
        </ShowWhiteSpace>
      )}

      <FormFieldReview
        prepopReviewing={prepopReviewing}
        prePopChange={prePopChange}
      >
        {!isPrePop.isPrePop && (
          <TextInputContainer inline={inline}>
            {prefix}
            <Input
              as={as}
              error={errorText}
              {...inputAttributes}
              onChange={handleChange}
              onBlur={handleBlur}
              inline={inline}
              aria-invalid={!!errorText}
              aria-describedby={errorId}
              data-qa={dataQa}
            />
            {suffix}
          </TextInputContainer>
        )}
        {isPrePop.isPrePop && (
          <Stack>
            {!isFormApproval && (
              <Input
                as={as}
                error={errorText}
                {...inputAttributes}
                value={prePopValue}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-invalid={!!errorText}
                aria-describedby={errorId}
                data-qa={dataQa}
              />
            )}

            {isFormApproval && oldValue.length > 0 && (
              <Stack>
                <Input
                  as={as}
                  error={errorText}
                  {...inputAttributes}
                  value={prePopOldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-invalid={!!errorText}
                  aria-describedby={errorId}
                  data-qa={dataQa}
                />

                <FormPrepopFieldReview prepopTPReviewing={true}>
                  <p>
                    <b>
                      {userTypeName} made an update to the pre-populated field
                      in the form. Here's the updated version.
                    </b>
                  </p>
                  <Input
                    as={as}
                    error={errorText}
                    {...inputAttributes}
                    value={prePopValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    aria-invalid={!!errorText}
                    aria-describedby={errorId}
                    data-qa={dataQa}
                  />
                </FormPrepopFieldReview>
              </Stack>
            )}

            {isFormApproval && oldValue.length == 0 && (
              <Input
                as={as}
                error={errorText}
                {...inputAttributes}
                value={prePopValue}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-invalid={!!errorText}
                aria-describedby={errorId}
                data-qa={dataQa}
              />
            )}

            {showCharacterCount && inputAttributes.maxLength && (
              <TextAreaCharacterCount>
                {inputAttributes.value?.length ?? 0}/{inputAttributes.maxLength}
              </TextAreaCharacterCount>
            )}
          </Stack>
        )}

        <Message id={errorId} error text={errorText} />

        {isPrePop.isPrePop && !prePopChange && !isEnquiry && (
          <PrePopSubText>
            <IconLightning />
            This data will be used to pre-fill future forms
          </PrePopSubText>
        )}

        {isPrePop.isPrePop && !prepopReviewing && !isEnquiry && (
          <PrePopUndoWrapper
            prePopChange={prePopChange}
            prepopReviewing={prepopReviewing}
          >
            <IconError />
            <p>Pre-filled details changed</p>
            <Button type="button" onClick={handleUndo} variant="naked">
              <b>Undo</b>
            </Button>
          </PrePopUndoWrapper>
        )}

        {isPrePop.isPrePop && prepopReviewing && prePopChange && (
          <PrePopSubTextReview
            prepopReviewing={prepopReviewing}
            prePopChange={prePopChange}
          >
            <IconError />
            <p>You've made a change. We'll notify the training provider.</p>
          </PrePopSubTextReview>
        )}
      </FormFieldReview>
    </Stack>
  );
};

export default TextInput;
