import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { boxShadow, color } from '../../../config';

// extend html element types when we switch between input or textarea
export type TInputAttributes =
  | InputHTMLAttributes<HTMLInputElement>
  | TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface IInlineProperty {
  inline?: boolean;
}
type TInput = TInputAttributes &
  IInlineProperty & { error?: string; as?: 'input' | 'textarea' };

export const TextInputContainer = styled.div<IInlineProperty>`
  display: flex;
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  align-items: center;
`;

export const Input = styled.input<TInput>`
  padding: 0.75em 1em;
  font-size: 1em;
  font-family: inherit;
  line-height: 3rem;
  color: ${color.grey.dark};
  border: 1px solid ${color.grey.light};
  border-radius: 0.5em;
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
  transition: border-color 0.2s, box-shadow 0.2s;
  height: ${(props) => (props.as === 'textarea' ? '12rem' : 'auto')};

  :hover {
    border-color: ${color.grey.base};
  }

  :focus {
    outline: none;
    box-shadow: ${boxShadow.primary};
  }

  :disabled {
    background-color: ${color.grey.lightest};
    border-color: ${color.grey.light};
  }

  ::placeholder {
    color: ${color.grey.dark};
  }

  ${(props) => {
    if (props.error) {
      return css`
        border-color: ${color.error.foreground};
        color: ${color.error.foreground};
        :focus {
          box-shadow: ${boxShadow.error};
        }
      `;
    }
    if (props.value) {
      return css`
        border-color: ${color.primary.base};
      `;
    }

    return null;
  }}
`;

export const TextAreaCharacterCount = styled.p`
  text-align: end;
  font-size: 12px;
  line-height: 18px;
  color: ${color.grey.base};
`;

export const PrePopSubText = styled.div`
  padding: 0.75em 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  font-family: inherit;
  color: ${color.grey.dark};
`;

export const PrePopUndoWrapper = styled.div`
  ${({ prepopReviewing, prePopChange }) => {
    if (prePopChange) {
      return `
    padding: 0.75em 1em;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-family: inherit;
    color: ${color.primary.base};
    p {
      padding-left: 1rem;
      padding-right: 2rem;
    }`;
    } else if (!prepopReviewing && !prePopChange) {
      return `
      display: none;
    `;
    } else {
      return `
      display: none;
    `;
    }
  }}
`;

export const FormFieldReview = styled.div`
  ${({ prePopChange, prepopReviewing }) => {
    if (prePopChange && prepopReviewing) {
      return `
        display: block;
        outline: none;
        box-shadow: ${boxShadow.primary};
        padding: 0.75em 1em;
        border-radius: 0.5em;
        background-color: ${color.mist.base}`;
    }
    return null;
  }}
`;

export const PrePopSubTextReview = styled.div`
  ${({ prePopChange, prepopReviewing }) => {
    if (prePopChange && prepopReviewing) {
      return `
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        font-size: 1.2rem;
        font-family: inherit;
        color: ${color.primary.base};
        text-align: left;
        p {
          padding-left: 1rem;
          padding-right: 2rem;
          color: ${color.grey.dark}
        }`;
    }
  }}
`;

export const FormPrepopFieldReview = styled.div`
  color: ${color.status.warning};
  ${({ prepopTPReviewing }) => {
    if (prepopTPReviewing === true) {
      return `
    p {
      padding-left: 1rem;
      padding-right: 2rem;
      padding-bottom: 1rem;
    }
      padding: 0.75em 1em;
      border-radius: 0.5em;      
      background-color: ${color.status.warningBg};
    `;
    }
    return `
    p {
      padding-left: 1rem;
      padding-right: 2rem;
      padding-bottom: 1rem;
    }
      outline: solid;
      outline-color: ${color.status.warning};
      outline-width: 1px;
      box-shadow: ${boxShadow.error};
      padding: 0.75em 1em;
      border-radius: 0.5em;      
      background-color: ${color.status.warningBg};
    `;
  }}
`;
