import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {
  deleteQueryParams,
  getAllQueryVar,
  urlAppendMultipleQueryVar,
} from '../../../../utils/query-vars';
import TableFilter from '../TableFilter';
import { FilterEntityResponse } from '../models/filter-response';
import { FilterItem } from '../models/filter-item';
import { TabColour } from '../../pill/tab-color';

const EnquiryStatusFilter = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<FilterEntityResponse[]>([]);

  const load = async () => {
    setItems([
      { id: '0', name: 'New' },
      { id: '1', name: 'Viewed' },
    ]);
    setLoading(false);
  };

  const onItemChanged = (selectedItems: FilterItem[]): void => {
    let url = '/';
    if (selectedItems.length === 0) {
      url = deleteQueryParams('enquiryStatuses', 'page');
    } else {
      url = deleteQueryParams('enquiryStatuses', 'page');
      url = urlAppendMultipleQueryVar(
        url,
        'enquiryStatuses',
        selectedItems.map((i) => i.id.toString()),
      );
    }

    navigate(url);
  };

  useEffect(() => {
    load();
  }, []);

  let enquiryNameQuery = getAllQueryVar('enquiryStatuses') ?? [];
  if (enquiryNameQuery.length === 1) {
    enquiryNameQuery = enquiryNameQuery[0].split(',');
  }

  const checkedItems: FilterItem[] = items.map((i) => ({
    id: i.id,
    name: i.name,
    color: TabColour.blue,
    selected: enquiryNameQuery.includes(i.id.toString()),
  }));

  return (
    <TableFilter
      filterName="enquiryStatuses"
      items={checkedItems}
      title="Status"
      disabled={loading}
      searchTitle="Search enquiry name"
      onItemChanged={onItemChanged}
    />
  );
};

export default EnquiryStatusFilter;
