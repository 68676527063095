import { navigate } from 'gatsby';
import React, { useCallback } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { formatDateTime } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import Table from '../../atoms/table/Table';
import { TableActions, TableRow } from '../../atoms/table/Table.style';
import { IconDelete, IconShow, IconRefresh, IconPlus } from '../../icons';
import { FullWidthContainer } from '../../layout/Layout.style';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { decodeToken, getUserClaims } from '../../../utils/jwt-decode';
import { FlexGrid } from '../../atoms/custom-elements/Flex.style';
import { deleteQueryParams, getAllQueryVar } from '../../../utils/query-vars';
import ActionLink from '../../atoms/custom-elements/linkaction.style';
import KebabMenu, { IKebabMenuItem } from '../../atoms/kebab-menu/KebabMenu';
import { IEnquiry } from '../../../context-providers/enquiries/enquiries-reducer';
import EnquiryNameFilter from '../../atoms/filter/filter-types/enquiry-name-filter';
import EnquiryStatusBadge from '../../atoms/status-badge/EnquiryStatusBadge';
import EnquiryStatusFilter from '../../atoms/filter/filter-types/enquiry-status-filter';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import DeleteEnquiry from '../../modals/enquiries/DeleteEnquiry';
import Button from '../../atoms/button/Button';
import NewEnquiry from '../../modals/enquiries/NewEnquiry';
import TransferLearner from '../../modals/enquiries/TransferLearner';
import EnquiryUtil from '../../../utils/enquiry-util';

const isBrowser = typeof window !== 'undefined';

const EnquiriesList = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const showModal = useShowModal();
  const tokenPermissions = getUserClaims();

  let permissions = [];
  let canDeleteEnquiry: boolean = false;
  let canTransferEnquiry: boolean = false;

  if (tokenPermissions) {
    permissions = tokenPermissions?.permissions ?? [];
    canDeleteEnquiry = userHasPermission(permissions, Permission.DeleteEnquiry);
    canTransferEnquiry = userHasPermission(
      permissions,
      Permission.TransferEnquiry,
    );
  }

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const clearAllFilters = () => {
    const url = deleteQueryParams('enquiryNames', 'enquiryStatuses');
    navigate(url || '');
  };

  const nameParams = extractQueryVars('enquiryNames');
  const statusParams = extractQueryVars('enquiryStatuses');

  const hasActiveFilters = nameParams.length > 0 || statusParams.length > 0;

  const enquiries = usePagedRequest<IEnquiry>('enquiries', {
    sortColumnName: 'enquiryDate',
    enquiryNames: nameParams.join(','),
    enquiryStatuses: statusParams.join(','),
  });

  const handleViewClick = (enquiry: IEnquiry) => {
    navigate(`/view-enquiry/?eid=${enquiry.id}`);
  };

  const handleTransferClick = (enquiry: IEnquiry) => {
    console.log(enquiry);

    showModal(TransferLearner, {
      enquiry,
      onSuccess: () => transferComplete(enquiry.id),
    });
  };

  const transferComplete = useCallback(async (enquiryId: string) => {
    await EnquiryUtil.setComplete(enquiryId);
    window.location.reload();
  }, []);

  const handleDeleteClick = (enquiry: IEnquiry) => {
    showModal(DeleteEnquiry, {
      onConfirmDelete: () => {
        onConfirmDelete(enquiry);
      },
      enquiryName: enquiry.enquiryName,
    });
  };

  const handleNewClick = () => {
    showModal(NewEnquiry);
  };

  const onConfirmDelete = async (enquiry: IEnquiry) => {
    const res = await fetchAPI<string>({
      path: `enquiries/${enquiry?.id}`,
      method: 'DELETE',
      responseType: 'text',
    });

    if (res.success) {
      window.location.reload();
    }
  };

  const dataMenuItems = (data: IEnquiry): IKebabMenuItem[] => {
    let menuItems: IKebabMenuItem[] = [];

    const viewItem: IKebabMenuItem = {
      name: 'View',
      qatag: `enquiries-btn-view-${
        data?.enquiryName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      disabled: false,
      onClick: () => {
        ReactGA.event({
          category: 'Button',
          action: 'View form',
        });
        handleViewClick(data);
      },
      iconLeft: <IconShow />,
      srOnly: <span className="sr-only">View {data.enquiryName} form</span>,
      className: 'not-rounded',
    };
    menuItems.push(viewItem);

    const deleteItem: IKebabMenuItem = {
      name: 'Delete',
      qatag: `forms-btn-delete-${
        data?.enquiryName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      disabled: !canDeleteEnquiry,
      onClick: () => {
        handleDeleteClick(data);
      },
      iconLeft: <IconDelete fill={!canDeleteEnquiry ? '#ccc' : ''} />,
      srOnly: <span className="sr-only">Delete {data.enquiryName} form</span>,
      className: 'not-rounded',
    };
    menuItems.push(deleteItem);

    const transferItem: IKebabMenuItem = {
      name: 'Transfer',
      qatag: `forms-btn-transfer-${
        data?.enquiryName?.replace(/\s/g, '-') ?? 'empty'
      }`,
      disabled: !canTransferEnquiry,
      onClick: () => {
        handleTransferClick(data);
      },
      iconLeft: <IconRefresh fill={!canTransferEnquiry ? '#ccc' : ''} />,
      srOnly: <span className="sr-only">Transfer {data.enquiryName} form</span>,
      className: 'not-rounded',
    };
    menuItems.push(transferItem);

    return menuItems;
  };

  const renderRow = (data: IEnquiry) => [
    <TableRow
      key="1"
      data-qa={`enquiryName-cell-${
        data?.enquiryName?.replace(/\s/g, '-') ?? 'empty'
      }`}
    >
      {data.enquiryName}
    </TableRow>,
    <TableRow
      key="2"
      data-qa={`enquiryFormName-cell-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`}
    >
      {data.formName}
    </TableRow>,
    formatDateTime(data.enquiryDate),
    <TableRow
      key="3"
      data-qa={`enquiryStatus-cell-${
        data?.formName?.replace(/\s/g, '-') ?? 'empty'
      }`}
    >
      <EnquiryStatusBadge
        status={data?.isViewed ? 1 : 0}
        data-qa={`status-lbl-${data?.formName?.replace(/\s/g, '-') ?? 'empty'}`}
      />
    </TableRow>,
    <TableActions key="4">
      <KebabMenu items={dataMenuItems(data)} canCreateForm={true}></KebabMenu>
    </TableActions>,
  ];

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2}>
        <GridFlex gutters={false} align="flex-end" justify="space-between">
          <GridFlexItem gutters={false} small={8}>
            <Stack stackMultiplier={1}>
              <h2>Enquiries</h2>
              <h4>
                You can manage enquiries, or transfer an enquiry to a learner
                record.
              </h4>
            </Stack>
          </GridFlexItem>
          <Button
            dataQa="forms-btn-new"
            iconLeft={<IconPlus responsive />}
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Enquiries - new enuiry',
              });
              handleNewClick();
            }}
          >
            Create new enquiry
          </Button>
        </GridFlex>

        <FlexGrid column={2} row={1}>
          <EnquiryNameFilter />
          <EnquiryStatusFilter />
          {hasActiveFilters && (
            <ActionLink onClick={clearAllFilters}>Clear all</ActionLink>
          )}
        </FlexGrid>

        <Table
          caption="List of enquiries"
          data={enquiries.data}
          headerItems={[
            { sortColumnName: 'enquiryName', label: 'ENQUIRY NAME' },
            { sortColumnName: 'enquiryFormName', label: 'ENQUIRY FORM' },
            { sortColumnName: 'enquiryDate', label: 'ENQUIRY DATE' },
            { sortColumnName: 'isViewed', label: 'STATUS' },
            { label: '' },
          ]}
          renderRow={renderRow}
          paged={enquiries.pagedParams}
        />
        <Paging pageCount={enquiries.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default EnquiriesList;
