import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import AuthWrapper from '../../components/layout/AuthWrapper';
import EnquiriesList from '../../components/organisms/enquiries-list/EnquiriesList';

const EnquiriesPage = () => {
  return (
    <AuthWrapper>
      <Head title="Enquiries" />
      <Layout section="enquiries" breadcrumbs={[{ label: 'Enquiries' }]}>
        <EnquiriesList />
      </Layout>
    </AuthWrapper>
  );
};

export default EnquiriesPage;
