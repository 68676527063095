import React from 'react';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { IconBin, IconClose, IconDelete } from '../../icons';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import {
  CombinedHeaderLeftRightIcon,
  CloseIconButton,
  SmallTitle,
} from '../ModalContent.style';

interface IDeleteEnquiry {
  onConfirmDelete: () => void;
  enquiryName: string;
}

const DeleteEnquiry = ({ onConfirmDelete, enquiryName }: IDeleteEnquiry) => {
  const closeModal = useCloseModal();

  return (
    <Stack stackMultiplier={2}>
      <CombinedHeaderLeftRightIcon>
        <div>
          <IconDelete width={25} height={25} />
          <h2>Delete Enquiry</h2>
        </div>
        <CloseIconButton
          aria-label="{getModalTitle()}"
          data-qa="deleteForm-btn-cancel-icon"
          onKeyDown={(e) => e.key === 'Escape' && closeModal()}
          tabIndex={0}
          onClick={() => closeModal()}
        >
          <IconClose width={25} height={25} />
        </CloseIconButton>
      </CombinedHeaderLeftRightIcon>
      <SmallTitle>
        Deleting the enquiry will remove it and all associated information from
        the system. Enquiries can't be recovered once deleted.
      </SmallTitle>
      <SmallTitle>Delete enquiry from {enquiryName}?</SmallTitle>
      <ButtonRowMobile>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
          dataQa="deleteData-btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirmDelete();
            closeModal();
          }}
          variant="warn"
          iconLeft={<IconBin />}
          dataQa="deleteData-btn-submit"
        >
          Delete
        </Button>
      </ButtonRowMobile>
    </Stack>
  );
};

export default DeleteEnquiry;
