import React from 'react';
import StatusBadge from './StatusBadge';
import { color } from '../../config';

const EnquiryStatus = [
  {
    label: 'New',
    color: color.status.newEnquiry,
  },
  {
    label: 'Viewed',
    color: color.status.pending,
  },
];

export interface IEnquiryStatusBadge {
  status: number;
}

const EnquiryStatusBadge = ({ status }: IEnquiryStatusBadge) => (
  <StatusBadge color={EnquiryStatus[status].color}>
    {EnquiryStatus[status].label}
  </StatusBadge>
);

export default EnquiryStatusBadge;
