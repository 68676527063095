import React from 'react';
import { IconKebab } from '../../icons';
import { MenuButton, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { KebabMenu as KebabMenuContainer } from './KebabMenu.stye';

export interface IKebabMenu {
  items: IKebabMenuItem[];
  canCreateForm: boolean;
}

export interface IKebabMenuItem {
  name: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  children?: React.ReactNode;
  qatag: string;
  disabled?: boolean;
  srOnly?: React.ReactNode;
  className?: string;
  onClick: () => void;
}

const KebabMenu = ({ items, canCreateForm }: IKebabMenu) => {
  return (
    <KebabMenuContainer
      menuButton={
        <MenuButton data-qa="button-kebab-menu">
          <IconKebab fill={!canCreateForm ? '#ccc' : ''} responsive />
        </MenuButton>
      }
      onItemClick={(e) => (e.keepOpen = false)}
    >
      {items.map((item, index) => (
        <MenuItem
          key={index}
          className={item.className}
          title={item.name}
          disabled={item.disabled}
          data-qa={item.qatag}
          onClick={item.onClick}
          value={item.name}
        >
          {item.iconLeft}&nbsp; <span>{item.name}</span>
          {item.srOnly}
        </MenuItem>
      ))}
    </KebabMenuContainer>
  );
};

export default KebabMenu;
